@import "./variables.less";

*{
  box-sizing: border-box;
  font-family: @fontFamily;
}

html{
  scroll-behavior: smooth;
}

body {
    margin: 0;
    color: #000000 !important;
}

svg {
    font-size: 24px;
}

.rs-picker {
    svg {
        font-size: unset;
    }
}